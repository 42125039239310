import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout title="not found">
    <p>You just hit a route that doesn't exist ... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
